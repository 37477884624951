export default class OffCanvas {
  constructor($layout, $layoutOffCanvas, $layoutPage, $layoutToggle) {
    $layoutToggle.on('click', event => {
      event.preventDefault()

      $layout.toggleClass($layout.data('toggle-class'))
      $layoutOffCanvas.toggleClass($layoutOffCanvas.data('toggle-class'))
      $layoutPage.toggleClass($layoutPage.data('toggle-class'))
      $layoutToggle.toggleClass($layoutToggle.data('toggle-class'))
    })
  }
}

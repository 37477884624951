export default class Slide {
  constructor($toggle, $element) {
    $toggle.on('click', event => {
      event.preventDefault()

      $element.slideToggle()
      $toggle.toggleClass($toggle.data('toggle-class') || '')
    })
  }
}

const loaded = {}
const loadingPromise = {}

export function loadScript(scriptUrl) {
  if (!scriptUrl) {
    throw new Error(
      `Can not load a script: Path to the script not set: ${scriptUrl}`
    )
  }

  if (loaded[scriptUrl]) {
    return Promise.resolve()
  }

  if (loadingPromise[scriptUrl]) {
    return loadingPromise[scriptUrl]
  }

  loadingPromise[scriptUrl] = new Promise((resolve, reject) => {
    const el = document.createElement('script')

    el.type = 'text/javascript'
    el.async = true
    el.src = scriptUrl

    el.addEventListener('load', () => {
      delete loadingPromise[scriptUrl]
      loaded[scriptUrl] = true
      resolve()
    })

    el.addEventListener('error', () => {
      delete loadingPromise[scriptUrl]
      reject(new Error(`Failed to load script ${scriptUrl}`))
    })

    document.head.appendChild(el)
  })

  return loadingPromise[scriptUrl]
}

export default class BookmarkCounter {
  constructor($el) {
    this.$el = $el
    this.addEventListeners()
    this.renderBookmarksCount(0)
  }

  addEventListeners() {
    EventBus.on('bookmarks:count', this.onBookmarksCountSet.bind(this))
  }

  onBookmarksCountSet(_event, count) {
    this.renderBookmarksCount(count > 0 ? count : 0)
    count > 0 || EventBus.trigger('bookmarks:empty')
  }

  renderBookmarksCount(count) {
    this.$el
      .parent()
      .find('.js-bookmarks-total')
      .attr('data-bookmarks-count', count)
    this.$el.find('.js-count').html(count)
  }
}

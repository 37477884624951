import _uniq from 'lodash-es/uniq'
import { loadScript } from '@/util/load_script'

export const initialize = () => {
  global.window.acConsent = {
    ...window.acConsent,
    state: 'loading',
    load: maybeLoadSourcePoint
  }
  global.window.acConsent.loadingPromise = new Promise((resolve, reject) => {
    global.window.acConsent.resolveLoadingPromise = resolve
    global.window.acConsent.rejectLoadingPromise = reject
  })

  global.window.acConsent.evalVendorsPromise = new Promise(
    (resolve, reject) => {
      global.window.acConsent.resolveEvalVendorsPromise = resolve
      global.window.acConsent.rejectEvalVendorsPromise = reject
    }
  ).finally(evaluateRequestConsentOnLoad)

  setTimeout(() => {
    if (global.window.acConsent.state === 'loading') {
      setSourcePointBlocked()
    }
  }, 5000)
}

export const isSourcepointBlocked = () => {
  return global.window.acConsent.state === 'blocked'
}

export const waitForSourcePointConsent = () => {
  return Promise.all([
    global.window.acConsent.loadingPromise,
    global.window.acConsent.evalVendorsPromise
  ])
}

function maybeLoadSourcePoint(url) {
  return loadScript(url)
    .then(yay => {
      global.window.acConsent.state = 'loaded'
      global.window.acConsent.resolveLoadingPromise()
    })
    .catch(() => {
      setSourcePointBlocked()
    })
}

export const setSourcePointBlocked = () => {
  global.window.acConsent.state = 'blocked'
  global.window.acConsent.rejectLoadingPromise()
  global.window.acConsent.rejectEvalVendorsPromise()
  evaluateRequestConsentOnLoad() // if Sourcepoint gets blocked we still want to trigger requestConsent fallback behaviour
}

export const registerConsent = ({ vendor, onAdd, onRemove }) => {
  EventBus.on(`consent:add${vendor}`, onAdd)
  EventBus.on(`consent:remove${vendor}`, onRemove)
  // check if a consent:add or consent:remove event for relevant vendor has been triggered in the past
  hasConsent(vendor).then(hasIt => (hasIt ? onAdd() : onRemove && onRemove()))
}

export const hasConsent = vendor =>
  waitForSourcePointConsent()
    .then(() => realHasConsent(vendor))
    .catch(() => realHasConsent(vendor))

const OPT_OUT_VENDORS = ['WEBTREKK']
function realHasConsent(vendor) {
  // if sourcepoint was somehow blocked we only assume consent for opt-out vendors
  if (isSourcepointBlocked()) {
    return OPT_OUT_VENDORS.includes(vendor)
  }
  return consentedVendors().includes(vendor)
}

function evaluateRequestConsentOnLoad() {
  if (global.window.acConsent.requestConsentOnLoad) {
    requestConsent()
  }
}

export const requestConsent = () => {
  try {
    window._sp_.loadPrivacyManagerModal(
      window._sp_.privacyManager.id,
      window._sp_.privacyManager.hash
    )
  } catch {
    EventBus.trigger('adblockFence:open')
  }
}

export const consentedVendors = () => {
  return window.nonIABVendors || []
}

export const addVendor = vendor => {
  if (window.nonIABVendors) {
    const vendors = _uniq([...consentedVendors(), vendor])
    window.nonIABVendors = vendors
  }
  EventBus.trigger(`consent:add${vendor}`)
}

export const removeVendor = vendor => {
  if (window.nonIABVendors) {
    const vendors = consentedVendors().filter(
      consentedVendor => consentedVendor !== vendor
    )
    window.nonIABVendors = vendors
  }
  EventBus.trigger(`consent:remove${vendor}`)
}
